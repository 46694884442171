import React from "react";

import forestsouthafrica from "../../../assets/images/resources/forest-south-africa.png";

import { Link } from "gatsby";

import fbicon from "../../../assets/images/resources/facebook (1).png";

import twittericon from "../../../assets/images/resources/twitter (1).png";

import whatsappicon from "../../../assets/images/resources/whatsapp.png";

import pintersticon from "../../../assets/images/resources/pinterest.png";

import linkdinicon from "../../../assets/images/resources/linkedin.png";

export default function Outersucesscontentpage() {
  return (
    <section className="outer-sucess-wrapper pad60">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <img
              className="img-fluid"
              src={forestsouthafrica}
              alt="Wellness banner"
            />

            <div class="entry-content">

              <div className="pad30">
                <h4 class="hfn-author-name text-center padd-b10">

                </h4>

                <div class="single-share-box">
                  <div class="share-link-description">Share this</div>
                  <div class="share-buttons">
                    <Link
                      class="facebook"
                      to="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;t=From+Inner+Awareness+to+Outer+Success"
                      title="Facebook"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={fbicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="twitter"
                      to="https://twitter.com/share?url=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;text=From+Inner+Awareness+to+Outer+Success"
                      title="Twitter"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={twittericon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="whatsapp"
                      to="https://api.whatsapp.com/send?text=From%20Inner%20Awareness%20to%20Outer%20Success%20-%20https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F"
                      title="WhatsApp"
                      target="_blank"
                      rel="noreferrer"
                      data-action="share/whatsapp/share"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={whatsappicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="pinterest pinit-marklet"
                      to="//pinterest.com/pin/create/button/"
                      title="Pinterest"
                      target="_blank"
                      data-pin-config="above"
                      rel="noreferrer"
                      data-pin-do="buttonBookmark"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={pintersticon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                    <Link
                      class="linkedin"
                      to="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fheartfulness.org%2Fen%2Ffrom-inner-awareness-to-outer-success%2F&amp;title=From%20Inner%20Awareness%20to%20Outer%20Success&amp;summary=&amp;source=Heartfulness%20Primary"
                      title="LinkedIn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={linkdinicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="row">
                  <h4 class="hfn-author-name text-left pad-b10">
                    The First Heartfulness Forest in South Africa
                  </h4>
                  <div class="col-md-12 mt-2">
                    <p className="p-tag-offerings">
                      “Forests by Heartfulness” is an initiative to nurture, protect and conserve native, endemic and endangered tree species which was started at the headquarters of Heartfulness Institute in Hyderabad, India.
                    </p>
                    <p className="p-tag-offerings">

                      Planting trees has massive, positive environmental and spiritual impact: Have you ever felt immediately more relaxed when walking through nature? It is said that trees are able to store transmission or ‘pranahuti’ for thousands of years which helps the mental, physical and spiritual state of the environment and the people living there.

                    </p>
                    <p className="p-tag-offerings">

                      Karthik and Karisham are a brother-sister duo who are part of the Heartfulness youth in South Africa. We were inspired by the Heartfulness Forests initiative in India and decided to volunteer to help spread the greening efforts locally in South Africa.

                    </p>
                    <p className="p-tag-offerings">To do this, we have partnered with award-winning registered non-profit organization called Greenpop; headquartered in Cape Town, South Africa. </p>
                    <p className="p-tag-offerings">Greenpop works to restore ecosystems and empower environmental stewards through forest restoration, urban greening, food gardening, and environmental awareness projects across Sub- Saharan Africa. Founded in 2010, they have planted over 150,000 trees and inspired over 132,000 active citizens across South Africa, Zambia, Malawi, and Tanzania.</p>
                    <p className="p-tag-offerings"> Karthik and Karisham started fundraising to plant a Heartfulness Forest in September 2019. At that time, we managed to raise R10 049 which is enough for 84 trees. We were going to go to Knysna to plant these trees in July of 2020 but the project was put on hold due to covid.</p>

                    <p className="p-tag-offerings"> We restarted the campaign at the beginning of 2022 but we needed a way to reach people remotely, so we made a video that explains how and why we are doing this.</p>

                    <div class="col-md-6 pb-4 center-hfn-video">
                      <iframe
                        width="595"
                        height="260"
                        src="https://www.youtube.com/embed/er2rNCB-wbw"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <p className="p-tag-offerings">All the money donated on this page goes directly to Greenpop.The trees will be planted from the <b>2nd till the 9th</b> of October <b>2022</b> in Knysna.</p>
                    <p className="p-tag-offerings">
                      All donors will be updated once the trees are planted.</p>
                    <p className="p-tag-offerings">
                      All donors will receive a donor’s certificate after the event for being part of this initiative but if you would like, you can email     <a href="mailto:info@heartfulness.org">karisham.kara@gmail.com</a> to request a personalized tree certificate to gift to someone special for events like birthdays, anniversaries, graduations and more.</p>
                    <h2>Other ways you can help:</h2>
                    <p className="p-tag-offerings">Share the video, the more eyes that see it gives us higher chances of donations. Do not underestimate the power of sharing.
                      Share this video with as many people as possible and try your best to activate your personal network.</p>
                    <p className="p-tag-offerings">If you can, please connect us with any companies that may be able to donate, it will be greatly appreciated.</p>
                  </div>
                </div>
                <button className="btn btn-blue donate-btn"> Donate</button>
                <button className="btn btn-blue white-border share-btn mx-5"> Share Video</button>
              </div>

              {/* <div class="entry-meta">
                <span class="category-link">
                  Category:&nbsp;
                  <a href={() => false}>
                    HOH Series
                  </a>
                </span>
                <a
                 href={() => false}
                  title="10:22 am"
                  class="data-link"
                  rel="noreferrer"
                >
                  <time
                    class="entry-date updated"
                    datetime="2021-08-09T10:22:26+05:30"
                  >
                    August 9, 2021
                  </time>
                </a>
              </div> */}

              <div class="single-share-box mt-3">
                <div class="share-link-description">Share this post</div>
                <div class="share-buttons">
                  <Link
                    class="facebook"
                    to="https://www.facebook.com/sharer.php?u=https%3A%2F%2Fheartfulness.org%2Fin%2Ffrom-inner-awareness-to-outer-success%2F&amp;t=From+Inner+Awareness+to+Outer+Success"
                    title="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                      <img
                        className="img-fluid"
                        src={fbicon}
                        alt="Wellness banner"
                      />
                    </span>
                  </Link>
                  <Link
                    class="twitter"
                    to="https://twitter.com/share?url=https%3A%2F%2Fheartfulness.org%2Fin%2Ffrom-inner-awareness-to-outer-success%2F&amp;text=From+Inner+Awareness+to+Outer+Success"
                    title="Twitter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                      <img
                        className="img-fluid"
                        src={twittericon}
                        alt="Wellness banner"
                      />
                    </span>
                  </Link>
                  <Link
                    class="whatsapp"
                    to="https://api.whatsapp.com/send?text=From%20Inner%20Awareness%20to%20Outer%20Success%20-%20https%3A%2F%2Fheartfulness.org%2Fin%2Ffrom-inner-awareness-to-outer-success%2F"
                    title="WhatsApp"
                    target="_blank"
                    data-action="share/whatsapp/share"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                      <span class="soc-font-icon">
                        <img
                          className="img-fluid"
                          src={whatsappicon}
                          alt="Wellness banner"
                        />
                      </span>
                    </span>
                  </Link>
                  <Link
                    class="pinterest pinit-marklet"
                    to="//pinterest.com/pin/create/button/"
                    title="Pinterest"
                    target="_blank"
                    data-pin-config="above"
                    data-pin-do="buttonBookmark"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                      <img
                        className="img-fluid"
                        src={pintersticon}
                        alt="Wellness banner"
                      />
                    </span>
                  </Link>
                  <Link
                    class="linkedin"
                    to="https://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Fheartfulness.org%2Fin%2Ffrom-inner-awareness-to-outer-success%2F&amp;title=From%20Inner%20Awareness%20to%20Outer%20Success&amp;summary=&amp;source=Heartfulness%20India"
                    title="LinkedIn"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span class="soc-font-icon">
                      <img
                        className="img-fluid"
                        src={linkdinicon}
                        alt="Wellness banner"
                      />
                    </span>
                  </Link>
                </div>
              </div>

              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
