import React from "react";

import Seo from "../components/seo/index";

import Layout from "../layout/index";

import Forestbreadcreumb from "../components/resources/forest-south-africa/forest-breadcumb.jsx";


import ForestContentpage from "../components/resources/forest-south-africa/forest-content.jsx";


// markup
const Internationalday = () => {
  return (
    <div>
      <Seo title="Humans Of Heartfulness" />

      <Layout isSticky>
        <section>
          <Forestbreadcreumb />
        </section>

        <section>
          <ForestContentpage/>
        </section>
      </Layout>
    </div>
  );
};

export default Internationalday;
